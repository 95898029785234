import React, { useState } from 'react';
import Nav from './component/Nav';
import Home from './component/Home';
import Footer from './component/Footer';
import Newsevent from './component/Newsevent';

function App() {
  
  return (
    <>
    <Nav/>
    
{/* <Home/> */}
<Newsevent/>
<Footer/>
   

</>
  );
}

export default App;
